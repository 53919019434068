export const insertExternalCSS = (url: string | null) => {
   if (!url) return;
   const { head } = document;
   const link = document.createElement('link');
   link.type = 'text/css';
   link.rel = 'stylesheet';
   link.id = 'css';
   link.href = url;
   head.appendChild(link);
};
