import { store } from '../app/store';
import {
   Package,
   Method,
   PaymentMethodParam,
} from '../features/payment/paymentTypes';

export const findPaymentMethodByPackage = (
   selectedPackage: Package | Record<string, string>,
) => {
   const { methods } =
      store?.getState()?.payment?.paymentResponse?.paymentMethods || {};

   if (!methods) return null;

   return (
      methods.find(
         (method: Method) =>
            method.id === (selectedPackage as Package)?.paymentMethods?.[0].id,
      ) || null
   );
};

export const paymentInputsToState = (
   paymentInputs: Method | null,
): Record<string, string> => {
   if (!paymentInputs) return {};
   return paymentInputs.paymentMethodParams.reduce(
      (acc: Record<string, string>, item) => {
         acc[item.name] = '';
         return acc;
      },
      {},
   );
};

export const areAllInputsPopulated = (obj: Record<string, string>): boolean => {
   const objValues = Object.values(obj);
   if (objValues.length === 0) return false;
   return objValues.every(Boolean);
};
