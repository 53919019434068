import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { selectPaymentLayout } from '../../features/payment/paymentSlice';
import { insertExternalCSS } from '../../utils/helpers';

const SEO = () => {
   const { seoParams, css, font } = useSelector(selectPaymentLayout);
   const { description, favIcon, title } = seoParams || {};

   useEffect(() => {
      insertExternalCSS(css);
   }, [css]);

   return (
      <HelmetProvider>
         <Helmet
            title="Centili Payment Widget"
            meta={[
               {
                  name: `description`,
                  content: description,
               },
            ]}
            link={[
               { rel: 'icon', type: 'image/png', href: favIcon ?? '' },
               // { rel: 'stylesheet', href: font },
               {
                  rel: 'preload stylesheet',
                  as: 'style',
                  href: font || '',
                  crossOrigin: 'anonymous',
               },
            ]}
         />
      </HelmetProvider>
   );
};

export default SEO;
