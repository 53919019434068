import DynamicComponents from 'common/dynamicComponents/DynamicComponents';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectPaymentStore, getInitialPaymentAction } from './paymentSlice';
import Preloader from '../../common/preloader/Preloader';

const PaymentPage = () => {
   const dispatch = useDispatch();
   const getQueryFromURL = useLocation().search;
   const { loadingStatus, paymentResponse } = useSelector(selectPaymentStore);
   const { template } = paymentResponse;

   useEffect(() => {
      dispatch(getInitialPaymentAction(getQueryFromURL));
   }, []);

   if (loadingStatus) {
      return <Preloader />;
   }
   return (
      <DynamicComponents
         pageType="payment"
         components={template?.paymentWidgetColumns}
      />
   );
};

export default PaymentPage;
