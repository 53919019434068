import Text from 'common/text/Text';
import Button from 'common/button/Button';
import { browserHistory } from 'utils/history';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from '../../assets/img/logo/centili-logo.svg';
import { selectPaymentErrorStatus } from '../../features/payment/paymentSlice';

const ErrorFallback = () => {
   const { message, status } = useSelector(selectPaymentErrorStatus);
   const reportProblem = () => {
      window.open(' https://www.centili.com/support/faq', '_blank');
   };

   const refreshPage = () => {
      browserHistory.back();
   };

   return (
      <section className="error-fallback-container centered-content">
         <div className="logo-container">
            <Logo />
         </div>
         <div className={`error-image status-${status || 500}`} />
         <div className="main-content">
            <div className="error-text">
               {/* <Text variants="h1">Something went wrong</Text> */}
               <Text variants="h1">{message || 'Something went wrong'}</Text>
               <Text variants="p">
                  Page could not be loaded. Please try again later.
               </Text>
            </div>
            <div className="buttons-container">
               <Button className="transparent-button" onClick={reportProblem}>
                  Report the problem
               </Button>
               <Button onClick={refreshPage}>Refresh the page</Button>
            </div>
            <div className="error-text">
               <Text variants="p">
                  Customer support e-mail:{' '}
                  <span className="link-color">
                     <span>
                        <a href="mailto:global@dcbhelp.com">global@dcbhelp.com</a>
                     </span>
                  </span>
               </Text>
            </div>
         </div>
      </section>
   );
};

export default ErrorFallback;
