import React from 'react';
import cx from 'classnames';
import Text from 'common/text/Text';

type ColProps = {
   children: React.ReactNode;
   label?: string;
   className?: string;
};

const Col = ({ children, label, className }: ColProps) => {
   return (
      <div className={cx('column', className)}>
         {label && (
            <Text className="col-label" variants="h3">
               {label}
            </Text>
         )}
         <div className="col-container">{children}</div>
      </div>
   );
};

export default Col;
