import {
   selectPaymentLayout,
   selectPaymentStore,
} from 'features/payment/paymentSlice';
import React from 'react';
import { useSelector } from 'react-redux';

const PackageSummary = () => {
   const { paymentResponse } = useSelector(selectPaymentStore);
   const { priceLocale, priceMaximumFractionDigits, priceMinimumFractionDigits } =
      useSelector(selectPaymentLayout);
   const { amount, currency, price, unit } =
      paymentResponse.status?.packageSummary || {};
   const formatedPriceLocale = priceLocale?.replace(/_/g, '-');

   return (
      <div className="status-package-summary-container">
         <div className="package-summary-left-container">
            <span>{amount}</span> <span>{unit}</span>
         </div>
         <div className="package-summary-right-container">
            {price.toLocaleString(formatedPriceLocale, {
               maximumFractionDigits: priceMaximumFractionDigits,
               minimumFractionDigits: priceMinimumFractionDigits,
            })}{' '}
            {currency}
         </div>
      </div>
   );
};

export default PackageSummary;
