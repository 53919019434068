import Label, { LabelProps } from 'common/label/Label';
import cx from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPaymentLayout } from 'features/payment/paymentSlice';
import Text from 'common/text/Text';
import { PaymentMethodParam } from '../../features/payment/paymentTypes';

export type InputProps = {
   showLabel?: boolean;
   className?: string;
   paymentMethodParams?: Partial<PaymentMethodParam>;
   errorLabel?: string;
} & React.InputHTMLAttributes<HTMLInputElement> &
   LabelProps;

const Input = ({
   showLabel = true,
   className,
   paymentMethodParams,
   errorLabel,
   ...rest
}: InputProps) => {
   const payment = useSelector(selectPaymentLayout);
   const { labelComponent } = payment;
   const { id, name, label, placeholder } = paymentMethodParams || {};

   const { value, onBlur, onFocus } = rest;
   const [isLabelMoved, setIsLabelMoved] = useState(false);

   const onFocusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
      setIsLabelMoved(true);
      onBlur?.(e);
   };
   const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
      if (value) return;
      setIsLabelMoved(false);
      onFocus?.(e);
   };
   const InputWithMovingLabel = (
      <div
         className={cx(
            'input-container',
            'moving-label',
            isLabelMoved && 'label-moved',
         )}
      >
         {showLabel && <Label {...rest} htmlFor={id} label={label} />}
         <div className={`container ${name}`}>
            <input
               name={name}
               onFocus={onFocusHandler}
               onBlur={onBlurHandler}
               className={cx('input', className)}
               {...rest}
            />
         </div>
         {errorLabel && (
            <div className="floating-error-label-container">
               <Text variants="h6" className="error-label">
                  {errorLabel}
               </Text>
            </div>
         )}
      </div>
   );

   const InputWithUpperLabel = (
      <div className={cx('input-container')}>
         {showLabel && <Label {...rest} htmlFor={id} label={label} />}
         <div className={`container ${name}`}>
            <input
               name={name}
               placeholder={placeholder}
               className={cx('input', className)}
               {...rest}
            />
         </div>
         {errorLabel && (
            <Text variants="h6" className="error-label">
               {errorLabel}
            </Text>
         )}
      </div>
   );

   return labelComponent === 'MOVING_LABEL'
      ? InputWithMovingLabel
      : InputWithUpperLabel;
};

export default Input;
