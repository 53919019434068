import GlobalContainer from 'common/layout/GlobalContainer';
import PaymentContainer from 'common/layout/PaymentContainer';
import LandingRoutes from 'routes/LandingRoutes';
import SEO from 'common/seo/SEO';
import { AnimatePresence } from 'framer-motion';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'common/errorFallback/ErrorFallback';

const App = () => {
   return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
         <SEO />
         <GlobalContainer>
            <PaymentContainer>
               <AnimatePresence exitBeforeEnter initial={false}>
                  <LandingRoutes />
               </AnimatePresence>
            </PaymentContainer>
         </GlobalContainer>
      </ErrorBoundary>
   );
};

export default App;
