import { axiosInstance } from 'app/axiosClient';
import {
   PaymentSubmitResponseType,
   PaymentSubmitRequestType,
   TransactionStatusResponse,
   MainContentResponse,
} from './paymentTypes';

export const getInitialPaymentService = (urlQuery: string) => {
   return axiosInstance.get<MainContentResponse>(`/payment_widget_api/${urlQuery}`);
};

export const getTransactionStatusService = (transactionId: number | null) => {
   return axiosInstance.get<TransactionStatusResponse>(
      `/payment_widget_api/transaction/?transactionId=${transactionId}`,
   );
};
export const getStatusService = (trid: string | null) => {
   return axiosInstance.get<MainContentResponse>(
      `/payment_widget_api/status?transactionId=${trid}`,
   );
};
export const initTransactionService = (
   transactionData: PaymentSubmitRequestType,
) => {
   return axiosInstance.post<PaymentSubmitResponseType>(
      '/payment_widget_api/transaction',
      transactionData,
   );
};
// TODO
export const getSuccessfulTransactionRedirectUrl = (transactionId: string) => {
   return axiosInstance.get<{ urlRedirect: string }>(
      `/payment_widget_api/transaction/?transactionId=${transactionId}`,
   );
};

// http://dev-api.cent-inet.com/payment_widget_api/status?transactionId=814
