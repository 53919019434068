import axios from 'axios';

const axiosBaseURL =
   process.env.REACT_APP_ENV === 'production'
      ? window.location.origin
      : // : 'https://stage.centili.com';
        'https://stage.centili.com';

export const axiosInstance = axios.create({
   baseURL: axiosBaseURL,
});
