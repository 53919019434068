/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { AppRoutes } from '../../constants/constants';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../app/store';
import { browserHistory } from '../../utils/history';

import {
   initTransactionService,
   getSuccessfulTransactionRedirectUrl,
   getInitialPaymentService,
   getTransactionStatusService,
   getStatusService,
} from './paymentAPI';
import {
   PaymentState,
   TransactionStatusResponse,
   MainContentResponse,
} from './paymentTypes';

const initialState: PaymentState = {
   loadingStatus: false,
   isTransactionStarted: false,
   paymentResponse: {},
   error: {},
};

// STATUS PAGE
export const getStatusAction = createAsyncThunk(
   'payment/getStatus',
   async (trid: string | null, { rejectWithValue }) => {
      try {
         const response = await getStatusService(trid);
         return response.data as MainContentResponse;
      } catch (error) {
         if (axios.isAxiosError(error)) {
            return rejectWithValue(error);
         }
      }
   },
);
export const getTransactionRedirectionAction = createAsyncThunk(
   'payment/getTransactionRedirection',
   async (transactionId: string, { rejectWithValue }) => {
      try {
         const response = await getSuccessfulTransactionRedirectUrl(transactionId);
         return response.data;
      } catch (error) {
         if (axios.isAxiosError(error)) {
            return rejectWithValue(error);
         }
      }
   },
);

export const pingStatusAction = createAsyncThunk(
   'payment/pingStatus',
   // async (transactionId: number | null) => {
   async (trid: string | null, { dispatch }) => {
      const getStatus = async () => {
         const { payload } = await dispatch(getStatusAction(trid));
         if (payload) {
            const { poolingPeriod, transactionId } = payload as MainContentResponse;
            if (!poolingPeriod && !transactionId) {
               clearInterval(pingStatusInterval);
               return payload;
            }
            return payload;
         }
         clearInterval(pingStatusInterval);
         return null;
      };

      const pingStatusInterval = setInterval(getStatus, 1000);
      return getStatus();
   },
);

export const getTransactionStatusAction = createAsyncThunk(
   'payment/getTransactionStatus',
   async (transactionId: number | null) => {
      const response = await getTransactionStatusService(transactionId);
      return response.data as TransactionStatusResponse;
   },
);

export const getInitialPaymentAction = createAsyncThunk(
   'payment/getInitialPayment',
   async (urlQuery: string, { dispatch, rejectWithValue }) => {
      try {
         const { data } = await getInitialPaymentService(urlQuery);
         const { poolingPeriod, transactionId, template } = data;
         if (template) {
            return data;
         }
         const getUrlStatus = async () => {
            const { payload } = await dispatch(
               getTransactionStatusAction(transactionId),
            );

            const { urlRedirect } = payload as TransactionStatusResponse;
            if (urlRedirect) {
               clearInterval(statusInterval);
               if (data.embedPaymentPage) {
                  window.location.href = urlRedirect;
               } else {
                  window.open(urlRedirect, '_blank');
               }
            }
         };

         const statusInterval = setInterval(getUrlStatus, poolingPeriod ?? 0);
         return data;
      } catch (error) {
         if (axios.isAxiosError(error)) {
            return rejectWithValue(error);
         }
         // clearInterval(statusInterval);
      }
   },
);

export const startTransactionAction = createAsyncThunk(
   'payment/startTransaction',
   async (transactionData: any, { dispatch, rejectWithValue }) => {
      try {
         const { data } = await initTransactionService(transactionData);
         const { transactionId, sepUrl } = data;

         // Redirection to SEP
         if (sepUrl && !transactionId) {
            window.location.href = sepUrl;
            return;
         }

         const getTransactionStatus = async () => {
            // Pooling calls for redirection URL
            if (transactionId) {
               const { payload, meta } = await dispatch(
                  getTransactionRedirectionAction(transactionId?.toString()) || {},
               );

               if (meta.requestStatus === 'rejected') clearInterval(statusInterval);

               const { urlRedirect } = (payload as TransactionStatusResponse) || {};
               if (urlRedirect) {
                  clearInterval(statusInterval);
                  window.location.href = urlRedirect;
               }
            }
         };

         const statusInterval = setInterval(getTransactionStatus, 1000);
         try {
            // getUrlStatus();
         } catch (error) {
            clearInterval(statusInterval);
         }
      } catch (error) {
         if (axios.isAxiosError(error)) {
            return rejectWithValue(error);
         }
      }
   },
);

export const paymentSlice = createSlice({
   name: 'payment',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getInitialPaymentAction.pending, (state) => {
            state.loadingStatus = true;
         })
         .addCase(getInitialPaymentAction.fulfilled, (state, action) => {
            state.paymentResponse = action.payload || {};
            state.loadingStatus = !!action.payload?.transactionId;
         })
         .addCase(getInitialPaymentAction.rejected, (state, action) => {
            state.loadingStatus = false;
            state.error.status = (action.payload as AxiosError).response?.status;
            state.error.message = (
               action.payload as AxiosError
            ).response?.data.message;
            browserHistory.push(`/payment-widget${AppRoutes.ERROR_ROUTE}`);
         })
         // TRANSACTION STATUS
         .addCase(getTransactionStatusAction.pending, (state) => {
            state.loadingStatus = true;
         })
         .addCase(getTransactionStatusAction.fulfilled, (state) => {
            state.loadingStatus = true;
         })
         .addCase(getTransactionRedirectionAction.rejected, (state, action) => {
            state.loadingStatus = false;
            state.error.status = (action.payload as AxiosError).response?.status;
            state.error.message = (
               action.payload as AxiosError
            ).response?.data.message;
            browserHistory.push(`/payment-widget${AppRoutes.ERROR_ROUTE}`);
         })
         // STATUS PAGE
         .addCase(getStatusAction.pending, (state) => {
            state.loadingStatus = true;
         })
         // status fullfiled
         .addCase(getStatusAction.fulfilled, (state, action) => {
            if (!action.payload) return;

            const { poolingPeriod, transactionId } = action.payload;
            if (poolingPeriod && transactionId) {
               state.loadingStatus = true;
               return;
            }

            state.loadingStatus = false;
            state.paymentResponse = action.payload;
         })
         // Start transaction action
         .addCase(getStatusAction.rejected, (state, action) => {
            state.loadingStatus = false;
            state.error.status = (action.payload as AxiosError).response?.status;
            state.error.message = (
               action.payload as AxiosError
            ).response?.data.message;
            browserHistory.push(`/payment-widget${AppRoutes.ERROR_ROUTE}`);
         })

         .addCase(startTransactionAction.pending, (state) => {
            state.isTransactionStarted = true;
         })
         .addCase(startTransactionAction.fulfilled, (state) => {
            state.isTransactionStarted = true;
         })
         .addCase(startTransactionAction.rejected, (state, action) => {
            state.isTransactionStarted = false;
            state.loadingStatus = false;
            state.error.status = (action.payload as AxiosError).response?.status;
            state.error.message = (
               action.payload as AxiosError
            ).response?.data.message;
            browserHistory.push(`/payment-widget${AppRoutes.ERROR_ROUTE}`);
         });
   },
});

// Selectors
export const selectPaymentStore = (store: RootState) =>
   store.payment as PaymentState;
export const selectPaymentLayout = (store: RootState) =>
   store.payment.paymentResponse;
export const selectPaymentLoaderStatus = (store: RootState) =>
   store.payment.loadingStatus;
export const selectPaymentErrorStatus = (store: RootState) => store.payment.error;
export const selectTransactionLoaderStatus = (store: RootState) =>
   store.payment.isTransactionStarted;
export default paymentSlice.reducer;
