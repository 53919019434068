import './index.css';
import './assets/style/main.scss';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import {
   BrowserRouter,
   unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';

import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { browserHistory } from './utils/history';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
   // <React.StrictMode>
   <Provider store={store}>
      {/* <BrowserRouter> */}
      <HistoryRouter basename="/payment-widget" history={browserHistory}>
         <App />
      </HistoryRouter>
      {/* </BrowserRouter> */}
   </Provider>,
   // </React.StrictMode>,
);

reportWebVitals();
