import React from 'react';
import cx from 'classnames';
import RadioButton from 'common/button/RadioButton';
import Dropdown from 'common/dropdown/Dropdown';
import Text from 'common/text/Text';
import Col from 'common/layout/Col';
import { useSelector } from 'react-redux';
import { selectPaymentLayout } from 'features/payment/paymentSlice';
import { Method, Package as PackageType } from '../../paymentTypes';

// type PackageVariants = 'LIST' | 'BOX' | 'DROPDOWN'; // change after values are provided - all the possible variants,defined here
type PackageVariants = 'LIST' | 'LIST_TWO_COLUMNS' | 'BOX'; // change after values are provided - all the possible variants,defined here
type PackageComponentObject = Record<PackageVariants, React.ReactElement>;

type PackageProps = {
   onClick: (item: PackageType) => void;
   selectedPaymentMethod: Method | null; // add a onClick param when backend is known
   selectedPackage: PackageType | Record<string, string>;
};

const PackageBox = ({
   onClick,
   selectedPaymentMethod,
   selectedPackage,
}: PackageProps) => {
   const { packages } = useSelector(selectPaymentLayout);
   const packagesList = packages.packages;

   return (
      <div tabIndex={0} role="button" className={cx('package', 'box')}>
         {packagesList.map((item, i) => {
            // const selectedPaymentMethodForPackage = item.paymentMethods.find(
            //    (paymentMethod) => {
            //       if (paymentMethod.id === selectedPaymentMethod?.id) {
            //          return paymentMethod;
            //       }
            //       return null;
            //    },
            // );

            return (
               // TODO Change key in future
               // eslint-disable-next-line jsx-a11y/no-static-element-interactions
               <div
                  key={i}
                  onClick={() => onClick(item)}
                  className={cx(
                     'package-item',
                     'box-item',
                     selectedPackage.uniqueReference === item.uniqueReference &&
                        'active',
                  )}
               >
                  <div className="package-item-container">
                     <span>
                        <Text className="price-container" variants="p">{`${
                           item.amount || ''
                        }`}</Text>
                     </span>

                     <span className="unit-container">
                        <Text className="unit" variants="p">
                           {item.unit}
                        </Text>
                     </span>
                  </div>
                  {/* <RadioButton
                     checked={
                        selectedPackage.uniqueReference === item.uniqueReference
                     }
                  /> */}
               </div>
            );
         })}
      </div>
   );
};

const PackageList = ({
   onClick,
   selectedPaymentMethod,
   selectedPackage,
}: PackageProps) => {
   const { packages } = useSelector(selectPaymentLayout);
   const packagesList = packages.packages;
   const ListVariant = packages.packageLayoutType;
   return (
      <div
         tabIndex={0}
         role="button"
         className={cx('package', 'list', {
            'two-columns': ListVariant === 'LIST_TWO_COLUMNS',
         })}
      >
         {packagesList.map((item, i) => {
            // const selectedPaymentMethodForPackage = item.paymentMethods.find(
            //    (paymentMethod) => {
            //       if (paymentMethod.id === selectedPaymentMethod?.id) {
            //          return paymentMethod;
            //       }
            //       return null;
            //    },
            // );

            return (
               <div
                  key={i}
                  aria-hidden="true"
                  onClick={() => onClick(item)}
                  className={cx(
                     'package-item',
                     'list-item',
                     selectedPackage.uniqueReference === item.uniqueReference &&
                        'active',
                  )}
               >
                  <div className="package-list-items">
                     <Text className="name-container" variants="p">
                        {item.amount || ''} {item.unit}
                     </Text>
                     <RadioButton
                        checked={
                           selectedPackage.uniqueReference === item.uniqueReference
                        }
                     />
                  </div>
               </div>
            );
         })}
      </div>
   );
};

const Package = ({ ...rest }: PackageProps) => {
   const { packages } = useSelector(selectPaymentLayout);
   const ListVariant = packages.packageLayoutType;
   const VARIANT = ListVariant as PackageVariants;

   const PACKAGE_COMPONENTS: PackageComponentObject = {
      LIST_TWO_COLUMNS: <PackageList {...rest} />,
      LIST: <PackageList {...rest} />,
      BOX: <PackageBox {...rest} />,
   };

   return <Col label={packages.title}>{PACKAGE_COMPONENTS[VARIANT]}</Col>;
};

export default Package;
