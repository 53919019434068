import { useSelector } from 'react-redux';
import { selectPaymentLayout } from 'features/payment/paymentSlice';

const Header = () => {
   const { seoParams } = useSelector(selectPaymentLayout);
   const { logoUrl } = seoParams || {};
   return (
      <header>
         {logoUrl && <img alt="" className="header-img" src={logoUrl} />}
         {/* <div>
            Future content if needed.
         </div> */}
      </header>
   );
};

export default Header;
