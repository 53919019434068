import cx from 'classnames';
import React from 'react';

type TextVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
// TODO - add proper class names after design
// type ClassVariants = 'title'| 'card-title' | 'example'
type ClassNamesProps = {
   // eslint-disable-next-line no-unused-vars
   [key in TextVariants]?: string;
};
type TextProps = {
   variants: TextVariants;
   className?: string;
   children: string | React.ReactNode;
};

const DEFAULT_CLASS_NAME: ClassNamesProps = {
   h1: 'heading-h1', // change after we know design-ex. title class
   h2: 'heading-h2', // sub-title class
   h3: 'heading-h3',
   h4: 'heading-h4',
   h5: 'heading-h5',
   h6: 'heading-h6',
   p: 'text',
};

const Text = ({ variants, className, children }: TextProps) => {
   const CustomTag = variants as keyof JSX.IntrinsicElements;
   return (
      <CustomTag className={cx(DEFAULT_CLASS_NAME[variants], className)}>
         {children}
      </CustomTag>
   );
};

export default Text;
