import React from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { selectPaymentLayout } from '../../features/payment/paymentSlice';

type GlobalContainerProps = {
   children: React.ReactNode;
   className?: string;
};

const GlobalContainer = ({ children, className }: GlobalContainerProps) => {
   const { template } = useSelector(selectPaymentLayout);
   const columnsNo = template?.paymentWidgetColumns.length;

   return (
      <motion.main
         initial={{ y: -30, opacity: 0 }}
         animate={{ y: 0, opacity: 1 }}
         transition={{ delay: 0.2 }}
         id="global-container"
         className={cx(className, {
            'global-container-default': columnsNo === 1,
         })}
      >
         {children}
      </motion.main>
   );
};

export default GlobalContainer;
