import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { selectPaymentStore, pingStatusAction } from '../payment/paymentSlice';
import DynamicComponents from '../../common/dynamicComponents/DynamicComponents';
import Preloader from '../../common/preloader/Preloader';

const STATUS_QUERY_KEY = 'transactionId';

const StatusPage = () => {
   const dispatch = useDispatch();
   const [searchParams] = useSearchParams();
   const trid = searchParams.get(STATUS_QUERY_KEY);

   const { loadingStatus, paymentResponse } = useSelector(selectPaymentStore);
   const { template } = paymentResponse;

   useEffect(() => {
      dispatch(pingStatusAction(trid));
   }, [trid, dispatch]);

   if (loadingStatus) {
      return <Preloader />;
   }
   return (
      <DynamicComponents
         pageType="status"
         components={template?.paymentWidgetColumns}
      />
   );
};

export default StatusPage;
