import React, { Suspense, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import Button from 'common/button/Button';
import Package from 'features/payment/components/packages/Package';
import PackageSummary from 'common/packageSummary/PackageSummary';
import PaymentMethod, {
   RegexValidationCallbackProps,
   ErrorState,
} from 'features/payment/components/paymentMethod/PaymentMethod';
import { useDebouncedCallback } from 'use-debounce';
import { useSearchParams } from 'react-router-dom';
import {
   findPaymentMethodByPackage,
   paymentInputsToState,
   areAllInputsPopulated,
} from 'utils/findPaymentMethodByPackage';
import Header from 'common/header/Header';

import {
   selectTransactionLoaderStatus,
   selectPaymentLayout,
   startTransactionAction,
} from '../../features/payment/paymentSlice';
import {
   PaymentWidgetColumn,
   Method,
   Package as PackageType,
   PaymentQueryForSubmitAction,
} from '../../features/payment/paymentTypes';
import imageDefaultFallback from '../../assets/img/image-component/imageDefaultFallback.svg';

// Components
const Input = React.lazy(() => import('../input/Input'));
const Description = React.lazy(() => import('../description/Description'));
const ImageComponent = React.lazy(() => import('../image/Image'));

export type ComponentsList =
   | 'IMAGE'
   | 'DESCRIPTION'
   | 'INPUT'
   | 'MERCHANT_PARAMS'
   | 'PACKAGE'
   | 'PAYMENT_METHOD'
   | 'FOOTER'
   | 'NEXT_BUTTON'
   | 'STATUS_DESCRIPTION'
   | 'STATUS_TITLE'
   | 'STATUS_IMAGE'
   | 'STATUS_REDIRECT_BUTTON'
   | 'STATUS_FAILED_BUTTON'
   | 'PACKAGE_SUMMARY';
type ComponentsObjProps = Record<ComponentsList, React.ReactNode>;
type TemplateColumnsProps = PaymentWidgetColumn;
type DynamicComponentsProps = {
   components: TemplateColumnsProps[] | undefined;
   pageType: string;
};
type PaymentInputValues = {
   [key: string]: string;
};

const DynamicComponents = React.memo(
   ({ components = [], pageType }: DynamicComponentsProps) => {
      const dispatch = useDispatch();
      const [searchParams] = useSearchParams();
      const queryForSubmitAction: PaymentQueryForSubmitAction = queryString.parse(
         searchParams.toString(),
      );
      // App state
      const { status, description, imgUrl, packages } =
         useSelector(selectPaymentLayout);
      const hasTransactionStarted = useSelector(selectTransactionLoaderStatus);

      // Local state
      const [selectedPackage, setSelectedPackage] = useState<
         PackageType | Record<string, string>
      >(packages?.packages?.[0] || {});

      const [selectedPaymentMethod, setSelectedPaymentMethod] =
         useState<Method | null>(findPaymentMethodByPackage(selectedPackage));

      const [paymentInputValues, setPaymentInputValues] =
         useState<PaymentInputValues>(paymentInputsToState(selectedPaymentMethod));

      const [errorMessages, setErrorMessages] = useState<ErrorState>({
         [selectedPaymentMethod?.paymentMethodParams?.[0].name || 'key']: '',
         buttonDisabled: true,
      });
      // Effects
      useEffect(() => {
         // Update payment input state when changing the package
         setPaymentInputValues(paymentInputsToState(selectedPaymentMethod));
      }, [selectedPaymentMethod]);
      // Handlers
      const onPackageChangedHandler = (packageItem: PackageType) => {
         const name = packageItem.uniqueReference;
         setSelectedPackage(packageItem);
         setSelectedPaymentMethod(findPaymentMethodByPackage(packageItem));
         setErrorMessages({
            [name]: '',
            buttonDisabled: true,
         });
         setPaymentInputValues(paymentInputsToState(selectedPaymentMethod));
      };

      const onChangePaymentMethodHandler = (paymentMethod: Method) => {
         const name = paymentMethod.id;
         setSelectedPaymentMethod(paymentMethod);
         setPaymentInputValues(paymentInputsToState(paymentMethod));
         setErrorMessages({
            [name]: '',
            buttonDisabled: true,
         });
      };
      const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
         setErrorMessages({
            buttonDisabled: true,
         });
         const { name, value } = e.target;

         setPaymentInputValues((prevState) => ({ ...prevState, [name]: value }));

         if (!value) {
            setErrorMessages((errors) => ({ ...errors, [name]: '' }));
         }
      };

      const debouncedRegexValidation = useDebouncedCallback(
         ({ name, regex, validationErrorLabel }: RegexValidationCallbackProps) => {
            if (!regex) {
               const hasValue = paymentInputValues[name].length > 0;
               setErrorMessages({
                  [name]: hasValue ? '' : 'This field is required',
                  buttonDisabled: !hasValue,
               });
               return;
            }

            const isRegexValid = Object.entries(paymentInputValues).every(
               ([key], index) =>
                  new RegExp(
                     selectedPaymentMethod?.paymentMethodParams?.[index].regex || '',
                  ).test(paymentInputValues[key]),
            );

            setErrorMessages({
               [name]: isRegexValid
                  ? ''
                  : validationErrorLabel ??
                    '### REGEX NOT PASSED - PAYMENT METHODS REGEX LABEL MISSING###',
               buttonDisabled: !isRegexValid,
            });
         },
         600,
      );

      const onNextBtnClickHandler = () => {
         const {
            clientPackageReference,
            clientTransactionReferenceId,
            language,
            serviceKey,
            country,
            apikey,
            storeFrontCode,
            ...rest
         } = queryForSubmitAction || {};

         const { paymentMethods } = selectedPackage as PackageType;
         const currentPackage = paymentMethods.find(
            (p) => p.code === selectedPaymentMethod?.code,
         );

         const dataObject = {
            paymentMethod: selectedPaymentMethod?.type,
            merchantToken: currentPackage?.merchantToken,
            transactionCreateRequest: {
               clientPackageReference,
               clientTransactionReferenceId,
               language,
               storeFrontCode,
               serviceKey: apikey,
               country: selectedPaymentMethod?.countryCode || country,
               currency: currentPackage?.currency,
               price: currentPackage?.price,
               providerCode: selectedPaymentMethod?.providerCode,
               parameters: {
                  ...rest,
                  ...paymentInputValues,
               },
            },
         };

         dispatch(startTransactionAction(dataObject));
      };

      const COMPONENTS: ComponentsObjProps = {
         IMAGE: (
            <ImageComponent
               url={imgUrl}
               alt="header-image"
               defaultUrl={imageDefaultFallback}
            />
         ),
         DESCRIPTION: (
            <Description
               type="description"
               className="description-component"
               text={description}
            />
         ),
         INPUT: <Input id="1" />,
         MERCHANT_PARAMS: <h1>Mr params</h1>,
         PACKAGE: (
            <Package
               onClick={onPackageChangedHandler}
               selectedPaymentMethod={selectedPaymentMethod}
               selectedPackage={selectedPackage}
            />
         ),

         PAYMENT_METHOD: (
            <PaymentMethod
               paymentInputValues={paymentInputValues}
               errorState={errorMessages}
               regexValidationCallback={debouncedRegexValidation}
               onClick={onChangePaymentMethodHandler}
               onChange={onInputChangeHandler}
               selectedPaymentMethod={selectedPaymentMethod}
               selectedPackage={selectedPackage}
            />
         ),
         FOOTER: <h1>Footer</h1>,
         NEXT_BUTTON: (
            <Button
               onClick={onNextBtnClickHandler}
               className="next-btn"
               disabled={
                  errorMessages.buttonDisabled ||
                  !areAllInputsPopulated(paymentInputValues)
               }
               isLoading={hasTransactionStarted}
            >
               Confirm
            </Button>
         ),
         STATUS_DESCRIPTION: (
            <Description
               type="status-description"
               className="status-description"
               text={status?.description}
            />
         ),
         STATUS_TITLE: (
            <Description
               type="status-title"
               className="status-title"
               text={status?.title}
            />
         ),
         STATUS_IMAGE: (
            <ImageComponent
               className="status-image"
               url={imgUrl}
               alt="status-image"
               defaultUrl={imageDefaultFallback}
            />
         ),
         STATUS_REDIRECT_BUTTON: (
            <Button
               className="status-redirect-button"
               onClick={() => {
                  window.location.href = status?.redirectUrl as any;
               }}
            >
               {' '}
               {status?.redirectButtonLabel}
            </Button>
         ),
         STATUS_FAILED_BUTTON: (
            <Button className="status-failed-button">Failed label</Button>
         ),
         PACKAGE_SUMMARY: <PackageSummary />,
      };

      return (
         <Suspense fallback={null}>
            <Header />
            {components?.map((column, i) => (
               <div key={`${column}+${i}`} className={`components-wrap-${i}`}>
                  {pageType === 'payment'
                     ? column.paymentWidgetComponents?.map((component, j) => (
                          <div
                             key={`${component}+${j}`}
                             className={`component-col ${component.toLowerCase()}`}
                          >
                             {COMPONENTS[component as ComponentsList]}
                          </div>
                       ))
                     : null}
                  {pageType === 'status'
                     ? column.paymentWidgetStatusComponents?.map((component, j) => (
                          <div key={`${component}+${j}`} className="component-col">
                             {COMPONENTS[component as ComponentsList]}
                          </div>
                       ))
                     : null}
               </div>
            ))}
         </Suspense>
      );
   },
);

export default DynamicComponents;
