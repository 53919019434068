import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import Dropdown from 'common/dropdown/Dropdown';
import Col from 'common/layout/Col';
import { selectPaymentLayout } from '../../paymentSlice';
import { Method, Package } from '../../paymentTypes';
import Input from '../../../../common/input/Input';

type PaymentBoxVariants = 'BOX' | 'LIST';
type PaymentBoxObject = Record<PaymentBoxVariants, React.ReactElement>;
export type RegexValidationCallbackProps = {
   name: string;
   regex: string | null;
   validationErrorLabel: string;
};
export type ErrorState = Record<string, string | boolean> & {
   buttonDisabled: boolean;
};

export type PaymentBoxProps = {
   onClick: (id: Method) => void;
   onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
   selectedPaymentMethod?: Method | null;
   selectedPackage: Package | Record<string, string>;
   paymentInputValues: Record<string, string>;
   errorState: ErrorState;
   regexValidationCallback: ({
      name,
      regex,
      validationErrorLabel,
   }: RegexValidationCallbackProps) => void;
};

const PaymentMethodBox = ({
   onClick,
   onChange,
   selectedPaymentMethod,
   selectedPackage,
   regexValidationCallback,
   errorState,
   paymentInputValues,
}: PaymentBoxProps) => {
   const {
      paymentMethods,
      priceLocale,
      priceMaximumFractionDigits,
      priceMinimumFractionDigits,
   } = useSelector(selectPaymentLayout);
   const formatedPriceLocale = priceLocale?.replace(/_/g, '-');
   return (
      <section>
         <div className={cx('payment-method', 'package', 'list', 'two-columns')}>
            {paymentMethods?.methods?.map((paymentMethod) => {
               const availablePaymentMethods = (
                  selectedPackage as Package
               )?.paymentMethods.find((sPackage) => {
                  if (paymentMethod.id === sPackage.id) {
                     return sPackage;
                  }

                  return null;
               });

               return (
                  <div
                     tabIndex={0}
                     role="button"
                     onClick={() => onClick(paymentMethod)}
                     key={paymentMethod.id}
                     className={cx(
                        'package-item',
                        'list-item',
                        !availablePaymentMethods?.price && 'disabled',
                        selectedPaymentMethod?.id === paymentMethod.id && 'active',
                     )}
                  >
                     <div className="icon-and-name-container">
                        <div className="icon-container">
                           <img
                              src={paymentMethod.iconUrl}
                              alt="icon"
                              className=""
                           />
                        </div>
                        <div className="name-container">{paymentMethod.label}</div>
                     </div>
                     <div className="price-container">
                        {availablePaymentMethods?.price.toLocaleString(
                           formatedPriceLocale,
                           {
                              maximumFractionDigits: priceMaximumFractionDigits,
                              minimumFractionDigits: priceMinimumFractionDigits,
                           },
                        )}{' '}
                        {availablePaymentMethods?.currency}
                     </div>
                  </div>
               );
            })}
         </div>

         {paymentMethods.methods.map((method) => {
            // TODO - Refactor
            const availablePaymentMethods = (
               selectedPackage as Package
            )?.paymentMethods.find((sPackage) => {
               if (method.id === sPackage.id) {
                  return sPackage;
               }
               return null;
            });
            if (!availablePaymentMethods) {
               return;
            }

            return method.paymentMethodParams?.map((paymentInput) => {
               return (
                  selectedPaymentMethod?.id === method.id && (
                     <Input
                        value={paymentInputValues[paymentInput.name]}
                        key={paymentInput.id}
                        errorLabel={errorState?.[paymentInput.name] as string}
                        paymentMethodParams={paymentInput}
                        className={`payment-method-param-input ${paymentInput.name}`}
                        placeholder={paymentInput.placeholder}
                        onChange={(e) => {
                           onChange(e);
                           if (
                              e.target.value.length === 0 ||
                              e.target.value.length > 3
                           ) {
                              regexValidationCallback({
                                 name: paymentInput.name,
                                 regex: paymentInput.regex,
                                 validationErrorLabel:
                                    paymentInput.warnInvalidInputFormat,
                              });
                           }
                        }}
                     />
                  )
               );
            });
         })}
      </section>
   );
};

const options: any = [];
const PaymentMethodDropdown = () => {
   return <Dropdown options={options} />;
};

const PaymentMethod = ({ ...rest }: PaymentBoxProps) => {
   const { paymentMethods } = useSelector(selectPaymentLayout);
   const PAYMENT_METHODS_COMPONENTS: PaymentBoxObject = {
      BOX: <PaymentMethodBox {...rest} />,
      LIST: <PaymentMethodBox {...rest} />,
      // DROPDOWN: <PaymentMethodDropdown />,
   };
   return (
      <Col label={paymentMethods.title}>
         {
            PAYMENT_METHODS_COMPONENTS[
               (paymentMethods.paymentMethodLayoutType as PaymentBoxVariants) ||
                  'BOX'
            ]
         }
      </Col>
   );
};

export default PaymentMethod;
