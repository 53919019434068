import React from 'react';
import cx from 'classnames';

type PaymentContainerProps = {
   children: React.ReactNode;
   className?: string;
};

const PaymentContainer = ({ children, className }: PaymentContainerProps) => {
   return (
      <section className={cx('payment-container', className)}>{children}</section>
   );
};

export default PaymentContainer;
