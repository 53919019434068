import cx from 'classnames';

export type LabelProps = {
   label?: string;
   htmlFor?: string;
   className?: string;
};

const Label = ({ label, htmlFor, className }: LabelProps) => {
   return (
      <label className={cx('label', className)} htmlFor={htmlFor}>
         {label}
      </label>
   );
};

export default Label;
