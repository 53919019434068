import React from 'react';
import cx from 'classnames';

type RadioButtonProps = {
   checked: boolean;
   className?: string;
};

const RadioButton = ({ checked, className }: RadioButtonProps) => {
   return (
      <div className={cx('radio-container', className)}>
         <div
            tabIndex={0}
            role="checkbox"
            aria-labelledby="button-label"
            aria-checked="true"
            className={cx('radio-btn', { checked })}
         />
      </div>
   );
};

export default RadioButton;
