import { motion } from 'framer-motion';
import Loader from '../../assets/img/circles-loader.gif';

const Preloader = () => {
   return (
      <motion.div
         data-testid="preloader"
         key="1"
         initial={{ opacity: 1 }}
         animate={{ opacity: 1 }}
         className="preloader-container"
      >
         {/* TODO - use loader from our server */}
         <img src={Loader} alt="loader" />
      </motion.div>
   );
};

export default Preloader;
