import StatusPage from 'features/status/StatusPage';
import { Navigate, Route, Routes } from 'react-router';
// import LayoutPage from 'features/layout/LayoutPage';
import PaymentPage from '../features/payment/PaymentPage';
import { AppRoutes } from '../constants/constants';
import ErrorRoute from './ErrorRoute';

const LandingRoutes = () => {
   return (
      <Routes>
         <Route path={AppRoutes.HOME_ROUTE} element={<PaymentPage />} />
         {/* <Route path={AppRoutes.PAYMENT_ROUTE} element={<PaymentPage />} /> */}
         <Route path={AppRoutes.STATUS_ROUTE} element={<StatusPage />} />
         <Route path={AppRoutes.ERROR_ROUTE} element={<ErrorRoute />} />
      </Routes>
   );
};

export default LandingRoutes;
