import React from 'react';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import BtnLoader from '../../assets/img/btn-loader.gif';
import { selectPaymentLayout } from '../../features/payment/paymentSlice';

type ButtonProps = {
   className?: string;
   children?: React.ReactNode;
   isLoading?: boolean;
   disabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
   className,
   children,
   isLoading = false,
   disabled = false,
   ...rest
}: ButtonProps) => {
   const { activateButtonLabel } = useSelector(selectPaymentLayout);
   const hasChildren = children && !isLoading ? children : activateButtonLabel;

   return (
      <button
         disabled={disabled}
         {...rest}
         type="button"
         className={cx('btn', className)}
      >
         {!isLoading && hasChildren}
         {isLoading && (
            <motion.span
               initial={{ y: -30, opacity: 0 }}
               animate={{ y: 0, opacity: 1 }}
            >
               <img src={BtnLoader} className="btn-loader" alt="" />
            </motion.span>
         )}
      </button>
   );
};

export default Button;
